import styled from '@emotion/styled';

import { Container } from 'modules/common/ui';

/**
 * Отображает корневой элемент страницы"Личный кабинет недоступен".
 */
export const Root = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;

  background: linear-gradient(
    180deg,
    #010101 6.75%,
    #020323 25.2%,
    #020323 100%
  );
`;
