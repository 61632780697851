import { ComponentPropsWithoutRef, FC } from 'react';

import { Icon } from './Icon';
import { Container } from './Unavailable.Container';
import { Root } from './Unavailable.Root';
import { Block } from './Unavailable.Block';
import { Heading } from './Unavailable.Heading';
import { Info } from './Unavailable.Info';
import { Contact } from './Unavailable.Contact';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает текст ошибки "Личный кабинет недоступен".
 */
export const Unavailable: FC<Props> = ({ ...props }) => (
  <Root size="full" {...props}>
    <Container size="large">
      <Block horizontalAlign="left">
        <Heading>Личный кабинет недоступен</Heading>
        <Info>
          По вопросу снятия блокировки обратитесь, пожалуйста, по номеру:
        </Info>
        <Contact>+7 499 649-90-59</Contact>
      </Block>

      <Block horizontalAlign="right">
        <Icon />
      </Block>
    </Container>
  </Root>
);
