import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Icon.Root';
import iconImg from './icon.png';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<'img'>, 'src'>;

/**
 * Отображает графическое оформление страницы "Личный кабинет недоступен".
 */
export const Icon: FC<Props> = ({ ...props }) => (
  <Root src={iconImg} {...props} />
);
