import styled from '@emotion/styled';

import { Container, lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент страницы ошибки 404.
 */
export const Root = styled(Container)`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 1rem;

  @include ${lessThanContainer('small')} {
    font-size: 0.625rem;
  }
`;
