import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

/**
 * Отображает заголовок страницы "Личный кабинет недоступен".
 */
export const Heading = styled.h1`
  font-size: 2.8rem;

  @media ${lessThanContainer('small')} {
    font-size: 2rem;
  }
`;
