import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Текст страницы ошибки 404.
 */
export const Text = styled.h1`
  margin-bottom: 0;
  margin-top: 0.75rem;

  font-size: 1.5rem;
  font-weight: 300;

  color: ${({ theme }) => theme.colors.light.text.secondary};

  @media ${lessThanContainer('small')} {
    margin-top: 0.25rem;
    font-size: 1rem;
  }
`;
