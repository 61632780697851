import { ComponentPropsWithoutRef, FC } from 'react';

import { Container } from 'modules/common/ui';

import { Root } from './NotFound.Root';
import { Heading } from './NotFound.Heading';
import { Text } from './NotFound.Text';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root>;

/**
 * Отображает текст ошибки 404 (не найдено).
 */
export const NotFound: FC<Props> = ({ ...props }) => (
  <Root {...props}>
    <Container size="full">
      <Heading>404</Heading>
      <Text>Страница не найдена или перемещена.</Text>
    </Container>
  </Root>
);
