import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

export const Root = styled.img`
  height: 25rem;

  @media ${lessThanContainer('medium')} {
    height: 15rem;
    margin-bottom: 2rem;
  }

  @media ${lessThanContainer('small')} {
    height: 10rem;
  }
`;
