import styled from '@emotion/styled';

import { lessThanContainer } from 'modules/common/ui';

/**
 * Отображает контакты страницы "Личный кабинет недоступен".
 */
export const Contact = styled.h3`
  font-size: 1.8rem;

  @media ${lessThanContainer('small')} {
    font-size: 1.5rem;
  }
`;
