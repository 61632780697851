import styled from '@emotion/styled';
import { FC } from 'react';
import { lessThanContainer } from 'modules/common/ui';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Горизонтальное выравнивание контента.
   */
  horizontalAlign?: 'left' | 'right';
};

/**
 * Экранирование от лишних props
 */
const EscapedBlock: FC<Props> = ({ horizontalAlign, ...props }) => {
  return <div {...props} />;
};

/**
 * Отображает блок сетки страницы "Личный кабинет недоступен".
 */
export const Block = styled(EscapedBlock)`
  flex: 1;

  text-align: ${({ horizontalAlign }) => horizontalAlign ?? 'left'};

  @media ${lessThanContainer('small')} {
    text-align: center;
  }
`;
