import styled from '@emotion/styled';

import { Container as UIContainer, lessThanContainer } from 'modules/common/ui';

/**
 * Корневой элемент страницы ошибки 404.
 */
export const Container = styled(UIContainer)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 1rem;
  color: #fff;

  @media ${lessThanContainer('small')} {
    flex-direction: column-reverse;
  }
`;
