import styled from '@emotion/styled';
import { lessThanContainer } from 'modules/common/ui';

/**
 * Отображает информацию страницы "Личный кабинет недоступен".
 */
export const Info = styled.p`
  font-size: 1.3rem;
  font-weight: 400;

  @media ${lessThanContainer('small')} {
    font-size: 1rem;
  }
`;
